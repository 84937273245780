// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --app-color-primary: #118afa;
  --app-color-primary-hover: #0575de;
  --app-color-primary-light: #5aadf9;
  --app-color-primary-light-hover: #4895d5;
  --app-color-primary-stroke: #ffffff;
  --app-color-primary-stroke-historic:#118afa;
  --app-color-border-primary: #118afa;
  --app-color-secondary: #183d7f;
  --app-color-secondary-hover: unset;
  --app-color-background: #118afa;
  --app-color-background-2: #118afab3;
  --app-color-confirm: #69bd7b;
  --app-color-confirm-hover: #4db062;

  --rounded-default: 9999px;

  --button-color-primary: #118afa;
  --button-color-success: #69bd7b;
  --button-color-primary-hover: #0575de;
  --button-color-secondary-hover: #118afa;
  --button-color-tertiary-hover: #118afa;
  --button-color-success-hover: #4db062;
  --button-color-primary-border: #118afa;
  --button-color-secondary-border: #118afa;
  --button-color-primary-text: #118afa;
  --button-color-primary-text-hover: #ffffff;
  --button-color-secondary-text: #118afa;
  --button-color-secondary-text-hover: #118afa;
  --button-color-tertiary-text: #118afa;
  --button-color-tertiary-text-hover: #ffffff;
}

.auth-background {
  background-image: linear-gradient(var(--app-color-background), var(--app-color-background-2), var(--app-color-background)),
  url('../assets/auth/login.jpg');
}
